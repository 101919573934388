import { Avalanche } from "@usedapp/core";
import { config } from "..";
import { ContractAddress } from "./types";

export const applicationContracts: { [key: string]: ContractAddress } = {
    "Mountain": {
        [Avalanche.chainId]: "0xC27C68C12085f331eFc603D8060e0014189302e9"
    },
    "PangolinPair": {
        [Avalanche.chainId]: "0x495FF9f783C1bC0DDa73B1506b3ce470E625bc6e"
    },
    "USDCPair": {
        [Avalanche.chainId]: "0x5425890298aed601595a70AB815c96711a31Bc65"
    }
}

export const getContract = (name: string, chainId: number) => {
    try {
        return applicationContracts[name][chainId] || applicationContracts[name][config.readOnlyChainId || 1];
    } catch {
        console.warn("Contract was read using readOnlyChainId.");
        return applicationContracts[name][config.readOnlyChainId || 1];
    }
}